<template>
  <div>
    <div v-for="document in items" v-bind:key="document.doc_id">

      <b-card  v-if="cardDisplay == 'detailed'" class="mb-2 myproj-card">
        <b-row class="d-flex myproj-upperrow">
          <div class="d-flex align-items-center library-card-tag">
            <img :src="'/assets/images/icon_library_documenttype.svg'"/>
            <span>{{document.mime_type}}</span>
          </div>
          <span class="p-new-color d-none">NEW</span>
          <span v-if="document.uploaded" class="library-card-date openSans-400 fz-12 mx-2">({{ document.uploaded }}) </span>
          <span v-if="document.status == ':UPLOADED'" class="openSans-300 fz-12 library-card-date mx-2 processing-status">
              <img class="va-middle mx-2" :src="'/assets/images/icon_processing_small.svg'"/>
              <span class="va-middle">Processing Ingestion</span>
          </span>
          <popover-dots v-if="$store.getters.canModifyLibrary" :document="document" :show-msg-box="showMsgBox"/>
        </b-row>
        <b-row class="myproj-lowerrow">
          <div class="text-left myproj-projectname library-projectname" @click="fileRoute(document.doc_id)" >{{ document.title }}</div>
        </b-row>
      </b-card>

      <b-card v-if="cardDisplay == 'simplified'" class="mb-2 myproj-card simplified-file-card">
        <b-row class="d-flex simplified-row">
          <div class="text-left myproj-projectname simplified-file-name" @click="fileRoute(document.doc_id)" >{{ document.title }}</div>
          <span v-if="document.uploaded" class="library-card-date openSans-400 fz-12 mx-2">({{ document.uploaded }}) </span>
          <div class="d-flex align-items-center library-card-tag simplified-file-type library-card-date">
            <img :src="'/assets/images/icon_library_documenttype.svg'"/>
            <span>{{document.mime_type}}</span>
          </div>
          <span v-if="document.status == ':UPLOADED'" class="openSans-300 fz-12 library-card-date mx-2 processing-status">
              <img class="va-middle mx-2" :src="'/assets/images/icon_processing_small.svg'"/>
              <span class="va-middle">Processing Ingestion</span>
          </span>

          <popover-dots v-if="$store.getters.canModifyLibrary" :document="document" :show-msg-box="showMsgBox"/>

        </b-row>
      </b-card>
    </div>


    <div class="mt-3" >
      <b-pagination
        v-model="currentPage"
        @change="pageChangeHandler"
        align="center"
        :total-rows="totalEntries"
        :per-page="pageSize"
        first-number
        last-number></b-pagination>
    </div>

  </div>
</template>

<script>
import LibraryService from "@/modules/library/services/LibraryService";
import PopoverDots from "@/modules/common/components/PopoverDots";

export default {
  name: 'LibraryList',
  components: {PopoverDots},
  data () {
    return {
      currentPage: 1
    }
  },
  props: {
    cardDisplay: String,
    page: Number,
    items: Array,
    totalEntries: Number,
    pageSize: Number,
    pageChangeHandler: Function,
    textFilter: String
  },
  methods: {
    fileRoute: function (fileId) {
      this.$router.push({
        path: `/${this.$store.getters.getInstance}/document/${fileId}`,
        query: {page: this.currentPage, text_filter: this.textFilter}
      })
    },
    showMsgBox(docId, refs) {
      let boxOne = ''
      let currentPopover = refs[`popover-${docId}`]
      currentPopover.$emit('close')

      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this document?', {
        title: 'Wait',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'popUp-primary',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        cancelVariant: 'popUp-cancel',
        centered: true,
        hideHeaderClose: false,
        footerClass: 'p-2'
      })
        .then(value => {
          boxOne = value
          if(boxOne === true) {
            this.removeDocument(docId)
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    removeDocument(docId) {
      LibraryService.remove(this.$store.getters.getInstance, docId).then(() => {
        this.pageChangeHandler(this.currentPage)
      }, error => {
        console.log(error)
      })
    }
  },
  created() {
    this.currentPage = this.page
  }
}
</script>

<style lang="scss" scoped>
.project-action-list li {
  list-style-type: none;
  cursor: pointer;
  margin-bottom: 15px;
  margin-top: 5px;
}

.project-action-list li span {
  vertical-align: sub;
  margin-left: 19px;
  color: var(--viridian);
  font-size: 15px;
}

.padding-zero {
  padding: 0px;
}

@media (min-width: 320px) and (max-width: 1024px) {
div.myproj-projectname.library-projectname {
    width: 100%;
    text-overflow: unset;
    overflow: unset;
    white-space: unset;
    margin-bottom: 20px;
  }
}

</style>