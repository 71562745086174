<template>
  <div class="container-base section-container-padding">
    <icon-page-header
      :icon="'icon_mycontent.svg'"
      :title="$t('myContent.header.title')" />
    <b-row>
      <div :class="is_mobile ? 'col-12' : 'col-6'" class="text-left">
        <div class="table-list-title my-2 library-title-entries openSans-100 fz-21"> {{ $t('library.list.title') }}
          <span class="section-description openSans-100 ml-1 fz-14" v-if="!preloader">({{ $tc('general.list.entries', totalItems, { count: totalItems }) }})</span>
        </div>
      </div>
      <div :class="is_mobile ? 'd-none' : 'col-6'" class="ta-right">
        <span class="margin-label-view tabs-display-margin-project openSans-400 fz-13">
          {{ $t('settings.menu.tab.myInstances.view') }}
        </span>
        <span class="display-change-icons ml-20" @click="changeDisplay('detailed')">
            <img :class="cardDisplay == 'detailed' ? 'active-display' : ''" :src="'/assets/images/icon_detailed.svg'"/>
        </span>
        <span class="display-change-icons ml-20" @click="changeDisplay('simplified')">
            <img :class="cardDisplay == 'simplified' ? 'active-display' : ''" :src="'/assets/images/icon_di_documentview.svg'"/>
        </span>
      </div>
    </b-row>
    <b-row> 
      <b-input-group class="qa-input-group">
        <b-form-input class="knowledge-qa-input-search" type="search"
                      :placeholder="$t('myApps.qaPairs.filters.search')"
                      v-model="textFilter"
                      @keyup.enter="searchLibrary">
        </b-form-input>
        <b-input-group-append>
          <img class="cursor-pointer knowledge-qa-input-search-icon" :src="'/assets/images/icon_knowledge_qa_search.svg'"
               @click="searchLibrary"/>
        </b-input-group-append>
      </b-input-group>
      <b-col cols="12" v-if="preloader">
        <div v-if="preloader">
          <b-col class="wait-response-spinner my-3">
            <b-spinner class="m-0" variant="success" label="Spinning"></b-spinner>
          </b-col>
        </div>
      </b-col>
      <b-col v-else cols="12">
        <library-list v-if="totalItems > 0"
          :cardDisplay="cardDisplay"
          :items="documents"
          :totalEntries="totalItems"
          :pageSize="pageSize"
          :page="currentPage"
          :textFilter="this.textFilter"
          :pageChangeHandler="changePage" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import IconPageHeader from '@/modules/common/components/IconPageHeader.vue'
import LibraryList from '@/modules/library/components/LibraryList.vue'
import LibraryService from '@/modules/library/services/LibraryService.js'
import store from '@/store'

export default {

  name: 'Library',

  data () {
    return {
      cardDisplay: 'detailed',
      documents: [],
      totalItems: 0,
      pageSize: 5,
      currentPage: this.$route.query.page*1 || 1,
      textFilter: this.$route.query.text_filter,
      preloader: false,
    }
  },
  components: {
    IconPageHeader,
    LibraryList
  },
  methods: {
    changeDisplay(display) {
      display == 'detailed' ? this.cardDisplay = 'detailed' : this.cardDisplay = 'simplified'
    },
    searchLibrary() {
      this.preloader = true
      var filters = {
        start: (this.currentPage - 1) * this.pageSize,
        quantity: this.pageSize,
        text_filter: this.textFilter || null
      }
      LibraryService.get(store.getters.getInstance, filters).then((response) => {
        this.documents = response.data.documents
        this.totalItems = response.data.total_entries
        this.preloader = false
      })
      .catch((error) => {
        console.log(error)
      })
    },
    changePage(value) {
      this.currentPage = value
      this.$router.replace({ name: "Library", params: { bot: this.$route.params.bot }, query: { page: this.currentPage , text_filter: this.textFilter} })
      this.searchLibrary()
    }
  },
  created() {
    if (this.$store.getters.hasSelectedInstance) {
      this.searchLibrary()
    }
  },
  computed:{
    currentInstance: function() {return this.$store.getters.getInstance},
    is_mobile() {
      const isMobile = window.matchMedia("only screen and (max-width: 1024px)")
      return isMobile.matches ? true : false
    }
  },
  watch: {
    currentInstance : function() {
      this.searchLibrary()
    }
  }

}
</script>
<style lang="scss">

.qa-input-group {
  padding-left: 15px;
}

.knowledge-qa-input-search-icon {
  border: 1px solid #ced4da;
  border-left:transparent;
  padding: 10px;
  border-radius: 0px 0.25rem 0.25rem 0px;
  margin-right: 12px;
  margin-bottom: 20px !important;
}

.knowledge-qa-input-search {
  max-width: 664px;
  min-width: 318px !important;
  height: 46px;
  border-right: transparent;
  margin-bottom: 20px !important;
}

  @media (min-width: 320px) and (max-width: 1024px) {

    .knowledge-qa-input-search {
      min-width: unset !important;
    }
  }
</style>